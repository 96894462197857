<template>
    <div class="main">
        <QsNavbar title="填写资料"></QsNavbar>
        <van-form @submit="onSubmit">
            <van-field
                v-model="post.full_name"
                label="您的姓名"
                name="full_name"
                placeholder="请输入"  maxlength="24"
                :rules="[{ required: true, message: '不能为空' }]"
            />
            <van-field
                v-model="post.phone"
                label="联系电话"
                name="phone"
                placeholder="请输入" type="digit"  maxlength="16"
                :rules="[{ required: true, message: '不能为空' }]"
            />   
            <van-field
                v-model="post.email"
                label="Email"
                name="email"
                placeholder="请输入"  maxlength="36"
                :rules="[{ required: true, message: '不能为空' },{ pattern:/^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/, message: '邮箱格式不正确!' }]"
            />    
            <van-field
                v-model="post.company"
                label="公司"
                name="company"
                placeholder="公司"  maxlength="128"
                :rules="[{ required: true, message: '不能为空' }]"
            />     
            <van-field
                v-model="post.industry"
                label="行业"
                name="industry"
                placeholder="行业" maxlength="128"
                :rules="[{ required: true, message: '不能为空' }]"
            /> 
            <div style="margin: 24px;">
                <van-button round block type="primary" native-type="submit">提交</van-button>
            </div>                                                
        </van-form>
    </div>
</template>

<script>
import { mapState,mapGetters } from 'vuex'
export default {
    data(){
        return{
            post:{
                full_name:"",
                phone:"",
                email:"",
                company:"",
                industry:""
            }
        }
    },
    mounted(){

    },
    methods:{
        onSubmit(values) {
            this.$ajax.post("/client/user/register",values).then(res=>{
                this.$notify({ type: 'success', message: '提交成功!'});   
                //this.$store.commit('updateUser',this.$store.state.id+"|"+this.$store.state.subscribe+"|"+this.$store.state.open_id+"|"+this.post.full_name);
                this.$store.commit('updateFullName',this.post.full_name);
                this.$router.go(-1);
            }).catch(e=>{})
        },
    },
    computed:{
    }
}
</script>
<style lang="scss" scoped>

</style>

